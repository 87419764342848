<template>
  <en-drawer :model-value="modelValue" title="新建对账单" @close="$emit('update:model-value', false)">
    <en-form :model="form.data">
      <en-form-item label="创建方式">
        <select-maintain
          v-model="form.data.type"
          :options="[
            { message: '单个创建', code: 'single' },
            { message: '批量创建', code: 'group' },
            { message: '分公司创建', code: 'parent' },
            { message: '业务员创建', code: 'salesman' }
          ]"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
          @change="form.type.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="网点">
        <select-maintain
          v-model="form.data.tenant"
          :ajax="{ action: 'GET /enospray/common/tenant', params: (params, shortName) => (params.payload = { shortName }) }"
          :props="{ label: 'shortName', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type.code === 'salesman'" label="业务员">
        <select-maintain
          v-model="form.data.salesmanId"
          :ajax="{ action: 'GET /enocloud/common/customer', params: (params, name) => (params.payload = { name }) }"
          :props="{ label: 'name', value: 'id' }"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.type.code === 'parent'" label="区域">
        <select-maintain
          v-model="form.data.tenantParent"
          :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['TNTPRT']) }"
          :props="{ label: 'message', value: '' }"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="日期范围">
        <en-date-picker v-model:start="form.data.startDate" v-model:end="form.data.endDate" type="daterange" class="w-full"></en-date-picker>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import dayjs from 'dayjs'

let startDate = ''
let endDate = ''
let now = dayjs()

if (now.date() >= 26) {
  startDate = now.set('D', 26).format('YYYY-MM-DD')
  endDate = now
    .set('M', now.month() + 1)
    .set('D', 25)
    .format('YYYY-MM-DD')
} else {
  startDate = now
    .set('M', now.month() - 1)
    .set('D', 26)
    .format('YYYY-MM-DD')
  endDate = now.set('D', 25).format('YYYY-MM-DD')
}

type IPaintBusinessStatementCreationDto = EnosprayStatementDefinitions['PaintBusinessStatementCreationDto'] & {
  type: EnosprayStatementDefinitions['LookupDto']
}

const formDataInit = (): IPaintBusinessStatementCreationDto => {
  return {
    type: { code: 'single', message: '', type: '', description: '' },
    tenantParent: { code: '', message: '', type: '', description: '' },
    tenant: {},
    salesmanId: '',
    startDate,
    endDate
  } as any
}

export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler() {
        if (!this.modelValue) this.form.data = formDataInit()
      }
    }
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        ajax: {
          submit: {
            action: 'POST /enospray/statement/paint/business/enoch',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          type: {
            change() {
              this.form.data = formDataInit()
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.form.submit()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
